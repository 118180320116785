<template>
  <div id="app">
    <header>
      <h1>Welcome to Currency Converter</h1>
    </header>
    <CurrencyConverter />
  </div>
</template>

<script>
import CurrencyConverter from './components/CurrencyConverter.vue';

export default {
  name: 'App',
  components: {
    CurrencyConverter,
  },
};
</script>

<style>
#app {
  font-family: Arial, sans-serif;
  text-align: center;
  margin-top: 20px;
}

header {
  background-color: #4CAF50;
  color: white;
  padding: 10px 0;
  margin-bottom: 20px;
  font-size: 1.5em;
}
</style>
