<template>
  <div class="currency-converter">
    <h1>Currency Converter</h1>
    
    <div class="converter">
      <input 
        type="number" 
        v-model="amount" 
        placeholder="Amount" 
        class="input-field"
      />

      <select v-model="fromCurrency" class="currency-select">
        <option v-for="(rate, currency) in rates" :key="currency" :value="currency">
          {{ currency }}
        </option>
      </select>

      <span>to</span>

      <select v-model="toCurrency" class="currency-select">
        <option v-for="(rate, currency) in rates" :key="currency" :value="currency">
          {{ currency }}
        </option>
      </select>

      <button @click="convertCurrency" class="convert-button">Convert</button>
    </div>

    <p v-if="result" class="result">
      {{ amount }} {{ fromCurrency }} = {{ result }} {{ toCurrency }}
    </p>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      amount: 1,
      fromCurrency: 'USD',
      toCurrency: 'NGN',
      rates: {},
      result: null,
    };
  },
  mounted() {
    this.fetchRates();
  },
  methods: {
    async fetchRates() {
      try {
        const response = await axios.get(
          'https://api.currencyapi.com/v3/latest?apikey=cur_live_xRA4kK7rqstM2V4BMoPC8hce7n4BAJ5fIaQihbGw'
        );
        this.rates = response.data.data;
      } catch (error) {
        console.error("Error fetching rates:", error);
      }
    },
    convertCurrency() {
      const fromRate = this.rates[this.fromCurrency]?.value || 1;
      const toRate = this.rates[this.toCurrency]?.value || 1;
      this.result = ((this.amount / fromRate) * toRate).toFixed(2);
    },
  },
};
</script>

<style scoped>
.currency-converter {
  max-width: 400px;
  margin: auto;
  font-family: Arial, sans-serif;
  text-align: center;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.converter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 20px;
}

.input-field {
  width: 80px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.currency-select {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.convert-button {
  padding: 10px 15px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.convert-button:hover {
  background-color: #218838;
}

.result {
  font-size: 1.2rem;
  color: #333;
  font-weight: bold;
}
</style>
